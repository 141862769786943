import React from "react"
import { Container } from "react-bootstrap"
import "./AwardsTimeline.scss"
import GGFXImage from "@components/elements/GGFXImage"
import { parseHTML } from "@lib/utils"

const AwardsTimeline = ({ module, imagetransforms, strapiID }) => {
  const { image, title, year, content, timeline, bg_color, style } = module
  return (
    <div className={`awards-timeline-block ${bg_color} ${style}`}>
      <Container>
      {image?.url && style === "award" &&
        <div className="awards-banner">
          <GGFXImage
            ImageSrc={image}
            altText={title}
            imagetransforms={imagetransforms}
            renderer="srcSet"
            imagename="page.award_year.image"
            strapiID={strapiID}
          />
        </div>}
        {/* {timeline?.length > 0 && */}
        <div className="award-content">
          {year &&<div className="year">{year}</div>}
          {title && <div className="title">{title}</div>}
          {content &&<div className="content">{parseHTML(content?.data?.content)}</div>}
          {timeline?.length > 0 &&<div className="timeline-bk-container">
            <div className="timeline-bk">
              {timeline?.map((item, i) => {
                return (
                  <div className="timeline">
                    <div className="index">{i+1}</div>
                    {parseHTML(item?.text?.data?.text)}
                  </div>
                )
              })}
            </div>
          </div>}
        </div>
        {/* } */}
        {image?.url && style === "image" &&
        <div className={`awards-banner ${style}`}>
          <GGFXImage
            ImageSrc={image}
            altText={title}
            imagetransforms={imagetransforms}
            renderer="srcSet"
            imagename="page.award_year.image"
            strapiID={strapiID}
          />
        </div>}
      </Container>
    </div>
  )
}

export default AwardsTimeline
