/* eslint-disable jsx-a11y/anchor-is-valid */
import Slider from "@components/elements/Slider/Slider"
import Star from "@components/icons/Star"
import googleReview from "@images/google-with-stars-dark.svg"
import trustpilotReview from "@images/trustpilot-dark.svg"
import clsx from "clsx"
import { formatDistanceStrict } from "date-fns"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./ReviewSlider.scss"
import { isEmpty } from "lodash"
import useLimitReviews from "@hooks/dataHooks/useLimitReviews"
import GGFXImage from "@components/elements/GGFXImage"
import { reviewsURL } from "@lib/urls"
import { Link } from "gatsby"
import useDeviceMedia from "@hooks/useDeviceMedia"

const ReviewSlider = ({ module, reviews: pageReviews }) => {
  const reviews = useLimitReviews()
  const { isMobile } = useDeviceMedia()
  const reviewsData = pageReviews?.length > 0  ? pageReviews : reviews?.filter(item => item.pages.length === 0)
  if (!module || isEmpty(reviewsData)) return null

  const { heading, title, bg_color } = module

  const isGrey = bg_color === "grey"
  const CTA = (
    <Link className="button button-orange more-btn" to={reviewsURL}>
      See all reviews
    </Link>
  )
  return (
    <div
      className={clsx(
        "review-slider-wrap",
        isGrey ? "section-p grey-bg" : "section-m"
      )}
    >
      <Container className="review-slider-container">
        <div className="d-flex">
          <div>
        <h2 className="title">{heading ? heading : "Why Our Clients Trust Us"}</h2>
        <div className="description">
          <p>{title? title : "Discover what our customers are saying about their experiences."}</p>
        </div>
        </div>
        {!isMobile && CTA}
        </div>
        <Slider size="sm" className={clsx("review-slider")}>
          {reviewsData.map((review) => (
            <ReviewCard key={review.id} review={review} />
          ))}
        </Slider>
        {isMobile && CTA}
        {/* <div className="review-logo-section">
          <div className="review-section google-review">
            <img src={googleReview} alt="google review" />
            <p className="review-text">
              <span className="review-count">4.8</span>
             
            </p>
          </div>
          <div className="divider" />
          <div className="review-section trustpilot-review">
            <img src={trustpilotReview} alt="trustpilot review" />
            <p className="review-text">
              <span className="review-count">4.9/5</span>
             
            </p>
          </div>
        </div> */}
      </Container>
    </div>
  )
}

const ReviewCard = ({ review }) => {
  const [readMore, setReadMore] = useState(false)
  const showReadMore = review.text.length > 200

  const truncatedText = `${review.text.slice(0, 150)}...`

  const starCount =
    {
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      five: 5,
    }[review.stars] || 5

  const formattedDate =
    review.date &&
    formatDistanceStrict(new Date(review.date), new Date(), {
      addSuffix: true,
    })

  return (
    <div className="review-card">
      <div className="d-flex card-bio">
      <GGFXImage
          ImageSrc={review?.image}
          altText={review.name}
          imagetransforms={review?.ggfx_results}
          renderer="srcSet"
          imagename={"review.image.image"}
          strapiID={review?.strapi_id}
        />
        <div>
      <p className="name">{review.name}</p>
      {formattedDate && <p className="date">{formattedDate}</p>}
      <div className="icons-wrap">
        {Array.from({ length: starCount }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Star color="orange" key={`star-icon-${index}`} />
        ))}
      </div>
      </div>
      </div>
      <p className="title-review">{review.title}</p>
      <p className="review">
        {showReadMore && !readMore ? truncatedText : review.text}
        {showReadMore && (
          <span
            className="read-more"
            onClick={() => setReadMore((prev) => !prev)}
          >
            {readMore ? "less" : "more"}
          </span>
        )}
      </p>
      
    </div>
  )
}

export default ReviewSlider