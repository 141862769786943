import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import "./TitleBlock.scss";
import { parseHTML } from "@lib/utils";
import CTALink from "@components/elements/CTALink";
import { ResponsiveContainer, StackedCarousel } from "react-stacked-center-carousel";
import CustomSlide from "./CustomSlide"; // Import the CustomSlide component

const TitleBlock = (props) => {
  const { sliderTitle, content, sliderCta, slide_image } = props?.module;
  const { strapiID, ggfx_results } = props
  const carouselRef = useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = useState(0);

  const handlePrevSlide = () => {
    carouselRef.current.goBack(); // Move to the previous slide
  };

  const handleNextSlide = () => {
    carouselRef.current.goNext(); // Move to the next slide
  };


  const handleActiveSlideChange = (newCenterDataIndex) => {
    // Set the new center slide index
    setCenterSlideDataIndex(newCenterDataIndex);
  };


  return (
    <div className="title-block">
      <Container>
        <div className="con">
          {parseHTML(sliderTitle?.data?.title)}
          <div className="description">
            {parseHTML(content?.data?.content)}
          </div>
          <div className="btn-block">
            {sliderCta?.map((cta, index) => (
              <CTALink
                cta={cta}
                key={cta.id}
                className={`button ${index === 0 ? "button-orange" : "button-blue"}`}
              />
            ))}
          </div>
        </div>
        <div className="slider-container" style={{ width: "100%", position: "relative" }}>
        <ResponsiveContainer
            carouselRef={carouselRef}
            render={(parentWidth, carouselRef) => {
              let currentVisibleSlide = 5;
              if (parentWidth <= 1024) currentVisibleSlide = 5;
              else if (parentWidth <= 767) currentVisibleSlide = 1;
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={(slideProps) => (
                    <CustomSlide
                      {...slideProps}
                      
                      strapiID={strapiID}
                      ggfx_results={ggfx_results}
                      onActiveSlideChange={handleActiveSlideChange}
                    />
                  )}
                  height={parentWidth < 768 ? 336 : 430}
                  slideWidth={parentWidth < 768 ? 280 : 360}
                  carouselWidth={parentWidth}
                  data={slide_image}
                  maxVisibleSlide={currentVisibleSlide}
                  fadeDistance={0} 
                  disableSwipe={parentWidth <= 767 ? false : true}
                  swipeSpeed={parentWidth < 768 ? 0.5 : 0.1}
                  currentVisibleSlide={currentVisibleSlide}
                  onActiveSlideChange={handleActiveSlideChange}
                  initialActiveSlideIndex={centerSlideDataIndex}
                />
              );
            }}
          />
                  {/* Arrow buttons */}
        <div className="controls">
          <button className="custom-arrow prev" onClick={handlePrevSlide}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="arrow-left-icon"><path d="M15.75 19.5L8.25 12L15.75 4.5" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          </button>
          <button className="custom-arrow next" onClick={handleNextSlide}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="arrow-right-icon"><path d="M8.25 4.5L15.75 12L8.25 19.5" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          </button>
        </div>

        </div>
      </Container>
    </div>
  );
};

export default TitleBlock;
